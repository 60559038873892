// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-donairs-js": () => import("./../../../src/pages/donairs.js" /* webpackChunkName: "component---src-pages-donairs-js" */),
  "component---src-pages-favorites-js": () => import("./../../../src/pages/favorites.js" /* webpackChunkName: "component---src-pages-favorites-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pizza-menu-js": () => import("./../../../src/pages/pizza-menu.js" /* webpackChunkName: "component---src-pages-pizza-menu-js" */),
  "component---src-pages-seafood-subs-js": () => import("./../../../src/pages/seafood-subs.js" /* webpackChunkName: "component---src-pages-seafood-subs-js" */),
  "component---src-pages-specials-js": () => import("./../../../src/pages/specials.js" /* webpackChunkName: "component---src-pages-specials-js" */)
}

